import { navigateTo } from '@mfl/framework';
import { prompt, PromptResult } from '@mfl/common-components';
import strings from './links.strings';

const CONTACT_SUPPORT_LINK =
  'https://support.wisestamp.com/hc/en-us/requests/new ';

export const buildURL = (urlAddress: string, queryStrings: string[] = []) => {
  const newUrl = new URL(urlAddress);
  queryStrings.filter(Boolean).forEach((queryString) => {
    const [key, value] = queryString.split('=');
    if (key && value) {
      newUrl.searchParams.append(key, value);
    }
  });
  return newUrl.href;
};

export const limitReachedPrompt = async () => {
  const confirmResult = await prompt({
    aid: 'LINKS_LIMIT_REACHED_CONFIRM',
    header: strings.linksLImitReachedConfirmHeader,
    question: strings.linksLImitReachedConfirmDescr,
    primaryButtonText: strings.linksLImitReachedConfirmOk,
    secondaryButtonText: strings.linksLImitReachedConfirmCancel,
    width: '500px',
  });
  if (confirmResult === PromptResult.Primary) {
    navigateTo(CONTACT_SUPPORT_LINK, { reload: true, newTab: true });
  }
};
