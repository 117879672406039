import { openDialog } from '@mfl/common-components';
import { Link } from '@wisestamp/links-gateway-sdk';
import { AccountBasicInfoResponse } from '@msl/user-gateway-sdk';

import AddEditLinkDialog from './add-edit-link-dialog.vue';
import strings from './links.strings';

// Define the input type for the dialog
interface AddEditLinkInput {
  link?: Link;
  accountData?: AccountBasicInfoResponse;
}

export async function addEditLink(
  link?: Link,
  accountData?: AccountBasicInfoResponse
): Promise<AddEditLinkInput | undefined> {
  const headerText = link ? strings.dialogTitleEdit : strings.dialogTitleAdd;

  return await openDialog<AddEditLinkInput, AddEditLinkInput>({
    aid: (link ? 'EDIT' : 'ADD') + '_LINK_DIALOG',
    headerText,
    component: AddEditLinkDialog,
    showTopSeparator: true,
    input: { link: clone(link), accountData },
  });
}

function clone(link?: Link): Link | undefined {
  if (!link) return undefined;

  return JSON.parse(JSON.stringify(link));
}
