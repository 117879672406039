<script setup lang="ts">
import {
  WsDialogSection,
  IDialog,
  WsButton,
  WsDialogActions,
  WsDialogForm,
  WsMenu,
  WsMenuItem,
} from '@mfl/common-components';
import { onMounted, ref } from 'vue';
import { Link } from '@wisestamp/links-gateway-sdk';
import QRCode from 'qrcode';
import strings from './links.strings';

type MenuOption = {
  value: string;
  label: string;
  icon: string;
};

enum FileType {
  jpeg = 'jpeg',
  png = 'png',
  svg = 'svg',
}

const props = defineProps<{
  dialog: IDialog<Link | undefined, Link>;
}>();
const canvas = ref<HTMLCanvasElement | null>(null);
const link = ref<string>('');
const qrOptions: object = {
  errorCorrectionLevel: 'H',
  type: 'image/png',
  scale: 7,
  margin: 5,
};

if (props.dialog.input) {
  link.value = props.dialog.input.redirectUrl as string;
}

const downloadQRCodeOptions: MenuOption[] = [
  {
    value: FileType.jpeg,
    label: strings.downloadOptionJpeg,
    icon: 'fa-regular fa-file-jpg',
  },
  {
    value: FileType.png,
    label: strings.downloadOptionPng,
    icon: 'fa-regular fa-file-png',
  },
  {
    value: FileType.svg,
    label: strings.downloadOptionSvg,
    icon: 'fa-regular fa-file-svg',
  },
];

const downloadQRCode = async (v: MenuOption) => {
  if (v.value in FileType && canvas.value !== null && !!props.dialog.input) {
    const linkElement = document.createElement('a');
    linkElement.setAttribute(
      'download',
      `${props.dialog.input.title}.${v.value}`
    );
    let hrefAttrValue = '';

    switch (v.value) {
      case FileType.svg:
        {
          const svgContent = await QRCode.toString(link.value, qrOptions);
          hrefAttrValue =
            'data:text/plain;charset=utf-8,' + encodeURIComponent(svgContent);
        }
        break;
      default:
        {
          const downloadType = `image/${v.value}`;
          hrefAttrValue = canvas.value
            .toDataURL(downloadType)
            .replace(downloadType, 'image/octet-stream');
        }
        break;
    }

    linkElement.setAttribute('href', hrefAttrValue);
    linkElement.click();
  }
};

onMounted(() => {
  QRCode.toCanvas(canvas?.value, link.value, qrOptions, function (error) {
    if (error) console.error(error);
  });
});
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="link-generate-qr-section">
      <canvas ref="canvas" class="canvas"></canvas>
    </WsDialogSection>
  </WsDialogForm>
  <WsDialogActions>
    <WsButton
      label="Cancel"
      color="gray-500"
      variant="outlined"
      aid="GENERATE_QR_CODE_DIALOG_CLOSE_BUTTON"
      @click="props.dialog.close()"
    />
    <WsButton
      icon="fa-regular fa-chevron-down"
      icon-end
      aid="GENERATE_QR_CODE_DIALOG_DOWNLOAD_BUTTON"
    >
      {{ strings.download }}

      <WsMenu aid="GENERATE_QR_CODE_DIALOG_DOWNLOAD_MENU">
        <WsMenuItem
          v-for="option in downloadQRCodeOptions"
          :key="`GENERATE_QR_CODE_DIALOG_DOWNLOAD_${option.value}`"
          :aid="`GENERATE_QR_CODE_DIALOG_DOWNLOAD_${option.value}`"
          @click="downloadQRCode(option)"
        >
          <div class="text-sm font-medium generate-qr-code-menu-item">
            <span :class="option.icon"></span>
            <span> {{ option.label }} </span>
          </div>
        </WsMenuItem>
      </WsMenu>
    </WsButton>
  </WsDialogActions>
</template>

<style lang="scss" scoped>
.link-generate-qr-section {
  padding: 0;
}
.generate-qr-code-menu-item {
  display: flex;
  gap: 10px;
}
</style>
