<script setup lang="ts">
import { ref, computed } from 'vue';
import isUrl from 'is-url';
import {
  WsInput,
  WsButton,
  IDialog,
  WsDialogActions,
  WsDialogSection,
  WsDialogForm,
  WsToggle,
} from '@mfl/common-components';
import {
  linksGateway,
  Link,
  ResponseErrors,
} from '@wisestamp/links-gateway-sdk';
import { AccountBasicInfoResponse } from '@msl/user-gateway-sdk';

import strings from './links.strings';
import { buildURL, limitReachedPrompt } from './utils';

// Update the type of IDialog to reflect the new return type
const props = defineProps<{
  dialog: IDialog<
    { link?: Link | undefined; accountData?: AccountBasicInfoResponse },
    { link?: Link | undefined; accountData?: AccountBasicInfoResponse }
  >;
}>();

const busy = ref(false);

// Extract link and accountData from dialog.input
const link = ref<Link>(
  props.dialog.input?.link || {
    id: '',
    title: '',
    redirectUrl: '',
    slug: '',
    type: '',
  }
);

const accountData = ref<AccountBasicInfoResponse>(
  props.dialog.input?.accountData || {}
);

const shouldSendNotifications = ref<boolean>(
  !!link.value.notificationEmails?.length || !!link.value.notificationEmail
);

const initialLink = ref<Link>({ ...link.value });

const hasChanges = computed(() => {
  return (
    JSON.stringify(link.value) !== JSON.stringify(initialLink.value) ||
    shouldSendNotifications.value !==
      (!!link.value.notificationEmails?.length ||
        !!link.value.notificationEmail)
  );
});

const redirectUrlDisplayValue: string =
  link.value.type == 'survey' && link.value.redirectUrl
    ? buildURL(link.value.redirectUrl, ['source=link'])
    : '';

const urlHostName = ref('');

const ownerEmail = computed(
  () =>
    link.value.ownerEmail || accountData.value?.userEmail || "the owner's email"
);

async function save() {
  busy.value = true;
  try {
    if (link.value.id) await updateLink();
    else await createLink();
  } catch (e) {
    // TODO: inform the user of the failure and allow him/her to try again
    console.error('error saving link', e);
    let errorMsg = 'Unknown Error';
    if (e instanceof Error) errorMsg = e.message;
    // if error is limit reached - show the prompt
    if (
      errorMsg.match(ResponseErrors.GATEWAY_ERROR_LINK_CREATION_EXCEED_QUOTA)
    ) {
      props.dialog.close();
      await limitReachedPrompt();
    }
  } finally {
    busy.value = false;
  }
}

async function createLink() {
  const res = await linksGateway.create({
    link: {
      title: (link.value.title ||= urlHostName.value),
      redirectUrl: link.value.redirectUrl,
      slug: link.value.slug,
    },
    shouldSendNotifications: shouldSendNotifications.value,
  });

  if (res.success) {
    props.dialog.close({ link: res.link, accountData: accountData.value });
  } else {
    console.error('create link failed', res.error);
    // TODO: inform the user
  }
}

async function updateLink() {
  const res = await linksGateway.update({
    id: link.value.id,
    redirectUrl: link.value.redirectUrl,
    title: (link.value.title ||= urlHostName.value),
    slug: link.value.slug,
    shouldSendNotifications: shouldSendNotifications.value,
    type: link.value.type,
  });

  if (res.success) {
    props.dialog.close({ link: res.link, accountData: accountData.value });
  } else {
    console.error('failed to update link', res.error);
  }
}

async function validateSlug(val: string) {
  if (!val) return true;

  if (!/^[a-zA-Z0-9-]{1,20}$/.test(val)) {
    return strings.slugValidationMessage;
  }

  try {
    const res = await linksGateway.isSlugExists({ slug: val });

    return (
      !res.exists ||
      (link.value.id && res.linkId === link.value.id) ||
      strings.slugUniqueMessage
    );
  } catch {
    return strings.generalError;
  }
}

function validateURL(val: string) {
  if (!val) return strings.addUrl;

  let urlString = val.trim();

  if (!/^https?:\/\//i.test(urlString)) {
    // If the URL is invalid and doesn't contain a protocol, try adding 'http://'
    urlString = 'http://' + urlString;
  }

  link.value.redirectUrl = urlString;

  if (isUrl(urlString)) {
    try {
      extractHostName(urlString);
      return true;
    } catch {
      // If the protocol exists but the URL is still invalid, return an error
      return strings.urlIsInvalid;
    }
  }

  return strings.urlIsInvalid;
}

function extractHostName(urlString: string) {
  const url = new URL(urlString);
  urlHostName.value = url.hostname;
}
</script>

<template>
  <WsDialogForm @submit="save">
    <WsDialogSection class="link-form-section">
      <div>
        <label for="LINK_DIALOG_URL">
          {{ strings.urlLabel }}
        </label>
        <WsInput
          v-if="link.type === 'survey'"
          :model-value="redirectUrlDisplayValue"
          aid="LINK_DIALOG_URL"
          size="md"
          :disabled="true"
        ></WsInput>
        <WsInput
          v-else
          v-model="link.redirectUrl"
          aid="LINK_DIALOG_URL"
          debounce="500"
          size="md"
          :placeholder="strings.urlPlaceholder"
          autofocus
          :rules="[validateURL]"
        ></WsInput>
      </div>
      <div>
        <label for="LINK_DIALOG_TITLE">
          {{ strings.titleLabel }}
          <span>
            {{ strings.optionalLabel }}
          </span>
        </label>
        <WsInput
          v-model="link.title"
          aid="LINK_DIALOG_TITLE"
          :placeholder="strings.titlePlaceholder"
          size="md"
          :disabled="link.type === 'survey'"
        />
      </div>
      <div>
        <div class="link-slug-label-section">
          <label for="LINK_DIALOG_URL_PREFIX">
            {{ strings.slugLabelPrefix }}
          </label>
          <label for="LINK_DIALOG_SLUG">
            {{ strings.slugLabel }}
            <span>
              {{ strings.optionalLabel }}
            </span>
          </label>
        </div>
        <div class="link-slug-section">
          <WsInput
            class="link-slug-disabled-input"
            aid="LINK_DIALOG_URL_PREFIX"
            size="md"
            model-value="wisestamp.link"
            disabled
          />
          <WsInput
            v-model="link.slug"
            aid="LINK_DIALOG_SLUG"
            debounce="500"
            size="md"
            :placeholder="strings.slugPlaceholder"
            :rules="[validateSlug]"
          >
            <template #prepend>
              <span>/</span>
            </template>
          </WsInput>
        </div>
      </div>
      <div class="link-alerts-section">
        <div class="alerts-label">
          Turn on link alerts
          <p>
            Receive email notifications to {{ ownerEmail }} when this link is
            clicked or scanned.
          </p>
        </div>
        <WsToggle
          v-model="shouldSendNotifications"
          aid="LINK_DIALOG_NOTIFICATIONS_TOGGLE"
        ></WsToggle>
      </div>
    </WsDialogSection>

    <WsDialogActions>
      <WsButton
        label="Cancel"
        color="gray-500"
        variant="outlined"
        aid="LINK_DIALOG_CANCEL_BUTTON"
        @click="props.dialog.close()"
      />
      <WsButton
        type="submit"
        color="primary"
        aid="LINK_DIALOG_SUBMIT_BUTTON"
        :label="strings.dialogApplyChangesButton"
        :loading="busy"
        :disabled="!hasChanges"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>

<style scoped lang="scss">
.link-form-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 560px;
  width: 560px;
  font-size: 13px;
  padding: 24px;

  label > :first-child {
    margin-top: 6px;

    > * {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.link-slug-label-section {
  display: flex;
  align-items: center;

  > :first-child {
    min-width: 140px;
  }

  > :nth-child(2) {
    flex-grow: 1;
  }
}

.link-slug-section {
  > .q-field--disabled:first-child {
    min-width: 140px;
    --ws-input-border-radius: 3px 0 0 3px;
    border-left: 0;

    *:disabled {
      color: #2d2e30;
      font-weight: 400;
    }

    > :first-child {
      > :first-child::before {
        background-color: rgb(var(--color-primary-50));
        border-right: 0;
      }

      > :first-child > :first-child {
        opacity: 1 !important;
      }
    }
  }

  > :nth-child(2) {
    width: 100%;
    --ws-input-border-radius: 0 3px 3px 0;

    > :first-child > :first-child > :nth-child(2) {
      color: rgb(var(--color-primary));
    }
  }

  display: flex;
}

:deep(.q-field__marginal) {
  font-size: 13px;
  padding-right: 4px;
  color: rgb(var(--color-gray-500));
}

.link-alerts-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .alerts-label {
    display: flex;
    flex-direction: column;
    color: rgb(var(--color-gray-500));

    p {
      color: rgb(var(--color-gray-400));
    }
  }
}

label > span {
  color: rgb(var(--color-gray-400));
}

@media only screen and (max-width: 600px) {
  .link-form-section {
    width: auto;
  }
}
</style>
